<template>
    <li v-if="! value.directlyFinishOnPackingSlip" class="line">
        <a :class="isCurrentActionCompleted ? 'current-action-completed' : ''">
            <div class="left" @click="showLineOverlay">
                <p class="amount">
                    <strong>{{ value.quantity.value | quantity }}</strong>
                </p>
                <p class="unit">{{ value.quantity.unit }}</p>
            </div>
            <div class="middle" @click="showLineOverlay">
                <h4>{{ value.description }}</h4>
                <p>
                    {{ packagingString }}
                </p>
            </div>
            <div class="right">
                <div
                    v-if="updating"
                    class="spinner"
                    data-uk-spinner
                />
                <input
                    v-else-if="action === 'load'"
                    :checked="value.loaded"
                    type="checkbox"
                    class="uk-checkbox"
                    :disabled="disableLoadingCheckbox"
                    @change="value.loaded ? $emit('deload') : $emit('load')"
                />
                <input
                    v-else
                    :checked="Number(value.quantityUndelivered) === 0"
                    type="checkbox"
                    class="uk-checkbox"
                    :disabled="Number(value.quantityUndelivered) === 0 || disable"
                    @change="$emit('unload')"
                />
            </div>
        </a>
    </li>
</template>

<style lang="scss">
    .line {
        a {
            display:               grid;
            grid-template-columns: 100px 1fr 100px;
            gap:                   15px;
            align-items:           center;
            padding:               10px 20px;
            background-color:      var(--item-background);
            border-bottom:         1px solid var(--app-background-color);
            border-radius:         5px;

            .left {
                display: flex;

                p {
                    margin:    0;
                    font-size: 16px;
                    color:     var(--dynamic-title-color);
                }
                .amount {
                    text-align:  right;
                    width:       50px;
                    margin-left: -15px;
                }
                .unit {
                    text-align: right;
                    width:      30px;
                    margin-left: 5px;
                }
            }
            .middle {
                text-align: left;
                word-break: break-word;

                h4,
                p {
                    margin: 0;
                    color:  var(--dynamic-title-color);
                }
                h4 {
                    font-size: 18px;
                }
                p {
                    font-size: 16px;
                }
            }
            .right {
                text-align:   right;
                margin-right: 5px;

                input {
                    width:         30px;
                    height:        30px;
                    border-radius: 5px;
                    z-index:       100;

                    i {
                        color: #fff;
                    }
                }
            }
        }
    }
    .current-action-completed {
        opacity: 0.5;
    }
    .spinner {
        margin-left: 10px;

        &::v-deep circle {
            stroke: #fff !important;
        }
    }
</style>

<script>
import createDialog           from '../../../../../support/createDialog';
import formatQuantity         from "@/support/formatQuantity";
import { getSettings }        from "@/support/settings";
import PackingSlipLineOverlay from './PackingSlipLineOverlay';
import { RIDE_STATUS }        from '@/constants';
import roundTo                from "@/support/roundTo";

export default {
    name:       "PackingSlipLine",

    components: {},

    props: {
        value:    { type: Object,  required: true },
        action:   { type: String,  required: true },
        ride:     { type: Object,  required: true },
        disable:  { type: Boolean, required: true },
        updating: { type: Boolean, required: true }
    },
    data() {
        return {
            settings: getSettings()
        }
    },
    computed: {
        disableLoadingCheckbox() {
            return (
                this.disable
                || this.ride.status.code === RIDE_STATUS.CONCEPT
                || (this.ride.status.code > RIDE_STATUS.LOADED && ! this.settings.less_restrictions_for_starting_delivery)
            );
        },
        isCurrentActionCompleted() {
            return (
                (this.action === 'load' && this.value.loaded)
                || (this.action === 'unload' && Number(this.value.quantityUndelivered) === 0)
            );
        },
        packagingString() {
            let parts = [];
            if (this.value.quantityComposition) {
                const composition = this.value.quantityComposition;
                if (composition.quantity) parts.push(this.$t('quantity_composition.quantity', { count:  formatQuantity(composition.quantity) }));
                if (composition.length)   parts.push(this.$t('quantity_composition.length',   { length: roundTo(composition.length, 5) }));
                if (composition.height)   parts.push(this.$t('quantity_composition.height',   { height: roundTo(composition.height, 5) }));
                if (composition.width)    parts.push(this.$t('quantity_composition.width',    { width:  roundTo(composition.width,  5) }));

            } else if (this.value.quantity.packaging) {
                const packagingData = this.value.quantity.packaging;
                if (Number(packagingData.packages) !== 0) {
                    parts.push(packagingData.packages + ' ' + this.$t('packaging.package', { count: Math.abs(packagingData.packages) }));
                }
                if (Number(packagingData.miniPackages) !== 0) {
                    parts.push(packagingData.miniPackages + ' ' + this.$t('packaging.mini_package', { count: Math.abs(packagingData.miniPackages) }));
                }
                if (Number(packagingData.layers) !== 0) {
                    parts.push(packagingData.layers + ' ' + this.$t('packaging.layer', { count: Math.abs(packagingData.layers) }));
                }
                if (Number(packagingData.pieces) !== 0) {
                    parts.push(packagingData.pieces + ' ' + this.$t('packaging.piece', { count: Math.abs(packagingData.pieces) }));
                }
            }
            return parts.join(' - ');
        }
    },
    methods: {
        showLineOverlay() {
            createDialog(this, PackingSlipLineOverlay, { line: this.value });
        },
    },
};
</script>
