<template>
    <div class="options-view">
        <div class="uk-container uk-container-large">
            <div v-if="warningText" class="warning-confirm">
                <div v-html="warningText" />
                <div class="button-container">
                    <div class="button" @click="warningText=null">Annuleren</div>
                    <div class="button" @click="finalizeDelivery(false)">OK</div>
                </div>
            </div>
            <item-heading
                :item="item"
                :ride="ride"
                :options-view-id="true"
                :toRight="true"
                :full-address="true"
            />
            <ol
                v-if="(generalReceiptComments.length > 0 || internalDeliveryComments.length > 0) && ! storingComment && ! finalizing"
                class="conversation"
            >
                <li v-if="generalReceiptComments.length > 0" class="comment">
                    <div
                        class="container"
                        v-for="comment in generalReceiptComments"
                        :key="`gen_receipt_comment_${comment.id}`"
                    >
                        <div class="message">
                            <i class="fal fa-lg fa-box-usd" />
                            <p class="item-background-chat">{{ comment.comment }}</p>
                        </div>
                        <div class="created-by general-packing-comment">
                            <span>{{ generateGeneralReceiptCommentString(comment) }}</span>
                        </div>
                    </div>
                </li>
                <li v-if="internalDeliveryComments.length > 0" class="comment">
                    <div
                        class="container"
                        v-for="comment in internalDeliveryComments"
                        :key="`int_del_com_${comment.id}`"
                    >
                        <div class="message">
                            <p class="item-background">{{ comment.comment }}</p>
                            <i class="right fal fa-lg fa-person-carry" />
                        </div>
                        <div class="created-by user-sent">
                            <span> {{ getPostedOnAt(comment) }}</span>
                            <strong>{{ comment.createdBy }}</strong>
                        </div>
                    </div>
                </li>
            </ol>
            <template v-if="! storingComment && ! finalizing">
                <ol>
                    <li
                        class="option"
                        :class="isNotified ? 'green-border' : 'red-border'"
                        @click="openOverlay('inform')"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-user" />
                        </div>
                        <div class="middle">
                            <h4>{{ notifyCustomerString }}</h4>
                        </div>
                    </li>
                    <li
                        class="option"
                        @click="openGoogleMaps"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-route" />
                        </div>
                        <div class="middle">
                            <h4>
                                {{ $t('open_route_maps') }}
                            </h4>
                        </div>
                    </li>
                    <li
                        v-if="containsDeliveryLocationPhotos"
                        class="option"
                        @click="openOverlay('deliveryLocation')"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-location" />
                        </div>
                        <div class="middle">
                            <h4>{{ $t('delivery_location') }}</h4>
                        </div>
                    </li>
                    <li
                        v-if="invoices !== null"
                        class="option"
                        :class="hasOutstandingAmount ? 'red-border' : 'green-border'"
                        @click="openOverlay('invoices')"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-file-invoice-dollar" />
                        </div>
                        <div class="middle">
                            <h4 v-html="invoiceString" />
                        </div>
                    </li>
                    <li
                        class="option"
                        :class="isLoaded ? 'green-border' : 'red-border'"
                        @click="$router.push({ name: 'item_loading_view', params: { item, ride }})"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-truck" />
                        </div>
                        <div class="middle">
                            <h4>{{ loadString }}</h4>
                        </div>
                    </li>
                    <li
                        class="option"
                        :class="isSigned ? 'green-border' : 'red-border'"
                        @click="openOverlay('sign')"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-clipboard-check" />
                        </div>
                        <div class="middle">
                            <h4>
                                <template v-if="isSigned">
                                    {{ $t('purchase_receipt_signed') }}
                                </template>
                                <template v-else>
                                    {{ $t('sign_purchase_receipt') }}
                                </template>
                            </h4>
                        </div>
                    </li>
                    <li
                        class="option"
                        :class="hasPhotosAttached ? 'green-border' : 'red-border'"
                        @click="openOverlay('photos')"
                    >
                        <div class="left">
                            <i class="fal fa-lg fa-camera" />
                        </div>
                        <div class="middle">
                            <h4>{{ $t('add_photos') }}</h4>
                        </div>
                    </li>
                </ol>
                <div class="buttons">
                    <span class="middle">
                        <!-- TODO when block/unblock button (signed, photo's, informed)?? -->
                        <div
                            v-if="! isFinalized"
                            class="finalize-button"
                            @click="finalizeDelivery"
                        >
                            <template v-if="isLoaded || settings.allow_incomplete_receipts">
                                {{ $t('finalize_delivery') }}
                            </template>
                            <template v-else>
                                {{ $t('load_before_finishing') }}
                            </template>
                            <i class="fal fa-box-check" />
                        </div>
                        <div
                            v-else
                            class="finalize-button"
                        >
                            {{ $t('delivery_finalized') }} <i class="fal fa-box-check" />
                        </div>
                    </span>
                    <div class="chat">
                        <button
                            @click="toggleCommentInput"
                            class="bottom"
                            data-uk-toggle="target: #chat-toggle; animation: uk-animation-slide-left"
                        >
                            <i
                                v-if="showCommentInput"
                                :class="internalDeliveryComment !== '' ? 'send-blue' : 'send-gray'"
                                class="fal fa-paper-plane"
                                @click="storeComment"
                            />
                            <i v-else class="fal fa-comment-alt" />
                        </button>
                        <div id="chat-toggle" class="box" hidden>
                            <textarea
                                ref="commentInput"
                                v-model="internalDeliveryComment"
                                :placeholder="$t('delivery_comments?')"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <ol v-else>
                <li class="loading-spinner">
                    <div data-uk-spinner="ratio: 4" />
                </li>
                <li class="loading-spinner">
                    <template v-if="storingComment">
                        {{ $t('storing_comment') }}
                    </template>
                    <template v-else>
                        {{ $t('finalizing_delivery') }}
                    </template>
                </li>
            </ol>
        </div>
    </div>
</template>

<style lang="scss">
    .options-view {
        margin-top:       20px;
        background-color: var(--app-background-color);

        .warning-confirm {
            position:        fixed !important;
            margin:          0 !important;
            justify-content: center !important;
            display:         flex !important;

            flex-direction: column;
            align-items:    center;
            gap:            30px;
            top:            0;
            left:           0;
            height:         100%;
            width:          100%;
            background:     var(--app-background-color);
            color:          var(--dynamic-title-color);
            z-index:        1000;

            .button-container {
                display: flex;
                gap:     20px;

                .button {
                    border: 1px solid var(--dynamic-title-color);
                }
            }
        }
        .item-header {
            display:          flex;
            justify-content:  space-between;
            gap:              10px;
            align-items:      center;
            background-color: transparent;
            min-height:       100px;
            color:            var(--dynamic-title-color);

            .left,
            .weight {
                display:         flex;
                flex-direction:  column;
                justify-content: space-between;
                gap:             20px;
                align-content:   center;
                align-items:     center;
                text-align:      center;
            }
            .left {
                h4 {
                    color:  var(--dynamic-title-color);
                    margin: 0;
                }
            }
            .middle {
                width:          100%;
                color:          var(--dynamic-title-color);
                flex-direction: column;
                flex:           1;
                white-space:    nowrap;
                overflow:       hidden;

                .address {
                    border:        1px solid var(--dynamic-title-color);
                    border-radius: 5px;
                    padding:       10px;

                    .delivery-description {
                        font-size:   .875em;
                        line-height: .875em;
                    }
                    &.customer {
                        margin-bottom: 10px;
                    }
                    .attention-of {
                        color:         var(--dynamic-title-color); //Nodig met toekenning in .middle?
                        margin:        0;
                        font-weight:   bold;
                        overflow:      hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .weight {
                .fa-weight-hanging {
                    margin-right: 0 !important;
                }
                span {
                    font-size:   1.25rem;
                    white-space: nowrap;
                }
            }
            .right {
                display: none;
            }
        }
        .conversation {
            .comment {
                margin: 0 0 10px 0;

                &::after {
                    content: "";
                    clear:   both;
                    display: table;
                }
                .container {
                    .message {
                        display:    flex;
                        gap:        20px;
                        margin-top: 5px;

                        p {
                            border-radius: 5px;
                            width:         100%;
                            margin:        0;
                            padding:       7px 20px;
                            font-size:     18px;
                            color:         var(--dynamic-title-color);
                        }
                        i {
                            background:    #FFFFFF;
                            padding:       10px;
                            border-radius: 50%;
                        }
                    }
                    .created-by {
                        margin:    0 0 0 20px;
                        font-size: 12px;
                    }
                    .general-packing-comment {
                        margin-left: 75px;
                        color:       var(--dynamic-title-color);
                    }
                    .user-sent {
                        margin-right: 75px;
                        text-align:   right;
                        color:        var(--dynamic-title-color);

                        strong {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
        .option {
            display:               grid;
            grid-template-columns: 50px 1fr;
            grid-template-rows:    1fr;
            grid-column-gap:       0;
            grid-row-gap:          0;
            border-radius:         5px;
            padding:               25px;
            background-color:      var(--item-background);
            color:                 var(--dynamic-title-gray-color);

            .left {
                grid-area: 1 / 1 / 2 / 2;

                .green-icon {
                    color: #2DCE98;
                }
                i {
                    margin-top: 6px;
                }
            }
            .middle {
                grid-area: 1 / 2 / 2 / 3;
                overflow:  hidden;

                h4 {
                    margin-bottom: 0;
                    color:         var(--dynamic-title-color);
                    white-space:   nowrap;
                    overflow:      hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .buttons {
            margin: 50px 0 100px;

            .middle {
                position:        relative;
                justify-content: center;
                display:         flex;
                margin:          0 auto;

                .finalize-button {
                    min-width:        265px;
                    position:         absolute;
                    border-radius:    5px;
                    padding:          20px;
                    text-align:       center;
                    font-size:        20px;
                    color:            #fff;
                    background-color: var(--item-blue-to-darkBlue);

                    i {
                        margin-left: 15px;
                        padding-top: 5px;
                    }
                }
            }
            .chat {
                width:    100%;
                position: relative;

                .bottom {
                    position:         absolute;
                    right:            0;
                    width:            75px;
                    height:           75px;
                    border-radius:    5px;
                    border:           none;
                    cursor:           pointer;
                    z-index:          100;
                    background-color: var(--item-blue-to-darkBlue);

                    i {
                        color:     #fff;
                        font-size: 30px;
                        padding:   22px 22px 22px 17px;
                    }
                }
                .box {
                    position:         relative;
                    height:           75px;
                    border-radius:    5px;
                    background-color: var(--item-blue-to-darkBlue);

                    textarea {
                        position:   absolute;
                        top:        10px;
                        left:       17px;
                        width:      85%;
                        resize:     none;
                        box-sizing: border-box;
                        height:     auto;
                        min-height: 50px;
                        max-height: 100px;
                        outline:    none;
                        padding:    5px 24px 0 24px;
                        overflow:   hidden;
                        font-size:  20px;
                    }
                }
            }
        }
        .green-border {
            border: 1px solid #2DCE98;
        }
        .red-border {
            border: 1px solid red;
        }
        .send-blue {
            background-color: var(--item-blue-to-darkBlue);
        }
        .send-gray {
            background:    $theme-light-gray;
            border-radius: 5px;
        }
    }
</style>

<script>
import { apiClient }   from '@/api';
import createDialog    from '@/support/createDialog';
import { getSettings } from "@/support/settings";
import ItemHeading     from '../ItemHeading';
import OptionOverlay   from '@/views/transportRide/overview/ride/item/options/overlay/OptionOverlay';
import {
    convertToLocaleDateString,
    convertToLocaleTimeString
} from '@/support/dateFunctions';
import {
    CONTEXT, ITEM_STATUS, ITEM_TYPE,
    NOTIFY_METHOD_EMAIL,
    NOTIFY_METHOD_PHONE,
    NOTIFY_METHOD_SMS,
    RIDE_STATUS,
    SINGLE_RIDE_INCLUDES,
} from '@/constants';

export default {
    name:       "ItemOptionsView",
    components: { ItemHeading },
    data() {
        return {
            finalizing:                false,
            internalDeliveryComment:   '',
            item:                      this.$route.params.item,
            reloadComputedInvoiceData: true, // Trick to correctly handle the computed properties regarding invoicing since the change tracking doesn't register all changes
            ride:                      this.$route.params.ride,
            settings:                  getSettings(),
            showCommentInput:          false,
            storingComment:            false,
            warningText:               null,
        };
    },
    computed: {
        containsDeliveryLocationPhotos() {
            return false; // Might be included later
        },
        generalReceiptComments() {
            return this.purchaseReceipt.contextualComments.filter((comment) => comment.context === CONTEXT.PURCHASE_GENERAL_RECEIPT);
        },
        googleMapsUrl() {
            const address = this.purchaseReceipt.pickUpAddress;

            let url            = "https://www.google.com/maps/dir/?api=1";
            let destinationUrl = '';

            if (address.address.trim() !== '') {
                destinationUrl += address.address.trim().replace(/\s/g, '+');
            }
            if (address.postcode.trim() !== '') {
                if (destinationUrl !== "") destinationUrl += "+";
                destinationUrl += address.postcode.trim().replace(/\s/g, '+');
            }
            if (address.city.trim() !== '') {
                if (destinationUrl !== "") destinationUrl += "+";
                destinationUrl += address.city.trim().replace(/\s/g, '+');
            }
            if (destinationUrl) destinationUrl = '&destination=' + destinationUrl;
            return url + destinationUrl + '&travelmode=driving';
        },
        hasOutstandingAmount() {
            return false; // Might be implemented later
        },
        hasPhotosAttached() {
            return (this.purchaseReceipt.isSigned && this.purchaseReceipt.attachments.length > 1) || (! this.purchaseReceipt.isSigned && this.purchaseReceipt.attachments.length > 0);
        },
        internalDeliveryComments() {
            return this.purchaseReceipt.contextualComments.filter((comment) => comment.context === CONTEXT.PURCHASE_RECEIPT);
        },
        invoices() {
            return null; // Might be implemented later
        },
        invoiceString() {
            return null // Might be implemented later
        },
        isFinalized() {
            return this.item.itemStatus === ITEM_STATUS.COMPLETED;
        },
        isLoaded() {
            return this.item.itemStatus >= ITEM_STATUS.LOADED;
        },
        isNotified() {
            return this.isNotifiedByEmail || this.isNotifiedByEmailAndSMS || this.isNotifiedByPhone || this.isNotifiedBySms;
        },
        isNotifiedByEmail() {
            if (! this.purchaseReceipt.relationNotifications) return false;

            for (const notification of this.purchaseReceipt.relationNotifications) {
                if (notification.notificationType === 'driver_on_the_way' && notification.notificationMethod === NOTIFY_METHOD_EMAIL) {
                    return true;
                }
            }
            return false;
        },
        isNotifiedByEmailAndSMS() {
            return this.isNotifiedByEmail && this.isNotifiedBySms
        },
        isNotifiedByPhone() {
            if (! this.purchaseReceipt.relationNotifications) return false;

            for (const notification of this.purchaseReceipt.relationNotifications) {
                if (notification.notificationType === 'driver_on_the_way' && notification.notificationMethod === NOTIFY_METHOD_PHONE) {
                    return true;
                }
            }
            return false;
        },
        isNotifiedBySms() {
            if (! this.purchaseReceipt.relationNotifications) return false;

            for (const notification of this.purchaseReceipt.relationNotifications) {
                if (notification.notificationType === 'driver_on_the_way' && notification.notificationMethod === NOTIFY_METHOD_SMS) {
                    return true;
                }
            }
            return false;
        },
        isSigned() {
            return this.purchaseReceipt.isSigned;
        },
        loadString() {
            return this.item.status <= ITEM_STATUS.LOADED
                ? this.$t('load')
                : this.$t('loaded')
            ;
        },
        notifyCustomerString() {
            if (! this.isNotified) return this.$t('inform_departure');

            const date = convertToLocaleDateString(this.purchaseReceipt.relationNotifications[0].sentAt);
            const time = convertToLocaleTimeString(this.purchaseReceipt.relationNotifications[0].sentAt);

            if (this.isNotifiedByEmailAndSMS) return this.$t('informed_by_on_at.email_and_sms', { date, time });
            else if (this.isNotifiedByEmail)  return this.$t('informed_by_on_at.email',         { date, time });
            else if (this.isNotifiedBySms)    return this.$t('informed_by_on_at.sms',           { date, time });
            else if (this.isNotifiedByPhone)  return this.$t('informed_by_on_at.phone',         { date, time });

            console.error('Unknown notification method'); //eslint-disable-line no-console
            return this.$t('inform_departure');
        },
        packingSlip() {
            return this.item.packingSlip;
        },
        purchaseReceipt() {
            if (! this.item.type || this.item.type !== ITEM_TYPE.PURCHASE_RECEIPT) return null;
            else                                                                   return this.item.purchaseReceipt;
        },
    },
    methods: {
        generateGeneralReceiptCommentString(comment) {
            let string = this.$t('general_receipt_comment');
            if (this.item.purchaseOrder.length > 1) string += ` (${this.$t('purchase_order').toLowerCase()}: ${comment.contextTarget})`;
            return string;
        },
        toggleCommentInput() {
            this.showCommentInput = ! this.showCommentInput
            setTimeout(() => {
                this.$refs.commentInput.focus();
            }, 200);
        },
        openGoogleMaps() {
            window.open(this.googleMapsUrl);
        },
        async openOverlay(action) {
            if (action === 'sign' && this.purchaseReceipt.isSigned) {
                return;
            }
            const dialog = await createDialog(this, OptionOverlay, { action, item: this.item, ride: this.ride });

            dialog.$on('purchaseReceiptUpdated', async (receipt) => {
                this.$set(this.item, 'purchaseReceipt', receipt);
                await this.updateStatus(RIDE_STATUS.IN_TRANSIT);
            });
        },
        getPostedOnAt(comment) {
            return this.$t('posted_on_at', {
                date: convertToLocaleDateString(comment.createdAt),
                time: convertToLocaleTimeString(comment.createdAt)
            });
        },
        async storeComment() {
            this.internalDeliveryComment = this.internalDeliveryComment.trim();
            if (this.internalDeliveryComment === '') return;

            try {
                this.storingComment = true;

                const data = {
                    comment:       this.internalDeliveryComment,
                    context:       CONTEXT.PURCHASE_RECEIPT,
                    contextTarget: this.purchaseReceipt.id,
                    relation:      this.purchaseReceipt.supplier.id
                };
                const response = await apiClient.post('comment/contextual_comments/', data);

                this.item.purchaseReceipt.contextualComments.unshift(response.data.data);

                this.internalDeliveryComment = '';
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.storingComment = false;
            }
        },
        async updateStatus(updatedStatus) {
            if (this.ride.status.code < updatedStatus) {
                try {
                    const response = await apiClient.put(
                        `transport/transport_rides/${this.ride.id}?include=${SINGLE_RIDE_INCLUDES}`,
                        { status: updatedStatus }
                    );
                    this.$set(this, 'ride', response.data.data);
                } catch (e) {
                    console.error(e); //eslint-disable-line no-console
                    throw e;
                }
            }
        },
        async finalizeDelivery(showWarning = true) {
            if (! this.isLoaded) {
                if (! this.settings.allow_incomplete_receipts) {
                    return;
                }
                if (showWarning) {
                    this.warningText = 'LET OP!<br><br>Niet alle regels zijn geladen,<br>deze zullen als niet (aantal 0) ontvangen worden opgeslagen. <br><br>Weet u dit zeker?';
                    return;
                }
            }
            try {
                this.finalizing = true;
                await apiClient.post(`transport/items/${this.item.id}/finalizeDelivery`);

                // Redirect to ride overview if all items are delivered, otherwise redirect to current ride item overview
                for (const rideItem of this.ride.items) {
                    if (
                        (! rideItem.type && ! rideItem.packingSlip)
                        || rideItem.id !== this.item.id
                        && (
                            (rideItem.packingSlip && rideItem.packingSlip.status !== 1) // TODO in the future check the item status for a packing slip as well
                            || (rideItem.purchaseReceipt && rideItem.itemStatus !== ITEM_STATUS.COMPLETED)
                        )
                    ) {
                        await this.$router.push({ name: 'loading_view', params: { rideId: this.ride.id } });
                        return;
                    }
                }
                await this.$router.push({ name: 'overview' });
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.finalizing = false;
            }
        }
    },
};
</script>
