<template>
    <li class="item">
        <item-heading
            :item="item"
            :ride="ride"
            :action="action"
            :disable="updating !== null && updating !== 'all'"
            :updating="updating === 'all'"
            @loadAll="$emit('loadAll')"
            @toggleItem="$emit('toggleItem', item)"
            @unloadAll="$emit('unloadAll', item)"
            class="item-heading"
        />
        <div class="content">
            <ol
                v-if="(packingSlip && (action === 'unload' || (action === 'load' && item.id === expandedItem))) || (purchaseReceipt && item.id === expandedItem)"
            >
                <template v-if="packingSlip">
                    <packing-slip-line
                        v-for="line of lines"
                        :value="line"
                        :ride="ride"
                        :action="action"
                        :disable="disableLine(line)"
                        :updating="updating === 'all' || updating === line.id"
                        :key="'psl_'+line.id"
                        @load="$emit('loadLine', line)"
                        @deload="$emit('deloadLine', line)"
                        @unload="$emit('unloadLine', line)"
                    />
                </template>
                <template v-else-if="purchaseReceipt">
                    <item-line
                        v-for="line of lines"
                        :key="'il_'+line.id"
                        :action="action"
                        :disable="disableLine(line)"
                        :ride="ride"
                        :item="item"
                        :updating="updating === 'all' || updating === line.id"
                        :value="line"
                        @lineLoadStatusChanged="$emit('lineLoadStatusChanged', $event)"
                    />
                </template>
            </ol>
        </div>
    </li>
</template>

<style src="./LoadUnload.scss" lang="scss">

</style>

<script>
import ItemLine        from "@/views/transportRide/overview/ride/item/ItemLine.vue";
import ItemHeading     from './ItemHeading';
import PackingSlipLine from './PackingSlipLine';
import {
    RIDE_STATUS,
    ITEM_TYPE
} from "@/constants";

export default {
    name:       "Item",
    components: { ItemHeading, ItemLine, PackingSlipLine },
    props:      {
        action:           { type: String,             required: true },
        expandedItem:     { type: Number,             default:  null },
        item:             { type: Object,             required: true },
        ride:             { type: Object,             required: true },
        updating:         { type: [ Number, String ], default:  null },
    },
    computed: {
        lines() {
            // TODO Driver v2 / Kijlstra
            // Now we receive the lines 2 times (item.lines & item.OBJECT.lines). Since the object can have unique includes @ lines we have to keep it this way.
            // Ideally we only want the lines 1 time.
            if      (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP)   return this.item.packingSlip.lines;
            else if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt.lines;
            else                                                                      return [];
        },
        packingSlip() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP) return this.item.packingSlip;
            else                                                               return null;
        },
        purchaseReceipt() {
            if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt;
            else                                                                 return null;
        },
    },
    methods: {
        disableLine(line) {
            return this.ride.status.code === RIDE_STATUS.CONCEPT
                || this.action === 'view'
                || (this.updating !== null && this.updating !== 'all' && this.updating !== line.id)
            ;
        }
    }
};
</script>
