<template>
    <div class="uk-container uk-container-large">
        <!-- Unloading class because originally we only unloaded orders in this place in the app (now loading a purchase receipt) -->
        <ol class="list unload">
            <item
                :key="item.id"
                :expanded-item="item.id"
                :item="item"
                :ride="ride"
                :updating="loading"
                action="load"
                @lineLoadStatusChanged="updateLineLoadStatus($event)"
                class="item-spacing"
            />
        </ol>
        <div v-if="settings.allow_incomplete_receipts" class="contextual-comment">
            <template v-if="deletingComment || storingComment">
                <div class="loading-spinner" :style="`top: ${scrollTop()}`">
                    <div data-uk-spinner="ratio: 2" />
                    <div v-if="deletingComment">
                        {{ $t('deleting_comment') }}
                    </div>
                    <div v-else>
                        {{ $t('storing_comment') }}
                    </div>
                </div>
            </template>
            <textarea
                v-model="contextualCommentText"
                class="comment-textarea"
                :placeholder="$t('received_extra_less')"
                :disabled="! commentIsEditable"
            />
            <div v-if="commentIsEditable" class="comment-action-buttons">
                <button
                    class="store-comment"
                    :disabled="disableStoringComment"
                    @click="contextualComment ? updateContextualComment() : storeContextualComment()"
                >
                    <i class="fal fa-save" />
                </button>
                <button
                    class="delete-comment"
                    :disabled="disableDeleteComment"
                    @click="deleteContextualComment"
                >
                    <i class="fal fa-trash" />
                </button>
            </div>
        </div>
        <button class="go-back" @click="goBack">
            <i class="fal fa-chevron-left" />
        </button>
    </div>
</template>

<style src="./LoadUnload.scss" lang="scss" />

<style lang="scss">
    .contextual-comment {
        display:       flex;
        margin-bottom: 30px;
        border-radius: 5px;
        overflow:      hidden;

        .comment-textarea {
            flex:          1;
            width:         100%;
            height:        200px;
            resize:        none;
            border-radius: 0;
            border:        none;
            padding:       10px;
            box-sizing:    border-box;
            font-family:   sans-serif;

            &:disabled { background: #ffffff; }
        }
        .comment-action-buttons {
            width:          75px;
            display:        flex;
            flex-direction: column;
        }
        .store-comment {
            border:     none;
            font-size:  30px;
            background: var(--theme-button-icon);
            color:      #fff;
            flex:       1;
        }
        .delete-comment {
            border:     none;
            font-size:  30px;
            background: #d4473b;
            color:      #ffffff;
            height:     33%
        }
    }
    .loading-spinner {
        position:        fixed !important;
        margin:          0 !important;
        justify-content: center !important;
        display:         flex !important;

        flex-direction: column;
        align-items:    center;
        gap:            30px;
        top:            0;
        left:           0;
        height:         100%;
        width:          100%;
        background:     var(--spinner-background-color);
        z-index:        1000;
    }
    .go-back {
        width:         75px;
        height:        75px;
        background:    var(--item-blue-to-darkBlue);
        border-radius: 5px;
        border:        none;
        text-align:    center;
        @include center();

        i {
            padding-top: 25px;
            color:       #fff;
            font-size:   25px;
            text-align:  center;
        }
    }
</style>

<script>
import { apiClient }   from '@/api';
import { getSettings } from "@/support/settings";
import Item            from './Item';
import {
    CONTEXT,
    ITEM_STATUS,
    PURCHASE_RECEIPT_LINE_LOAD_STATUS,
    RIDE_STATUS,
    SINGLE_RIDE_INCLUDES
} from '@/constants';

export default {
    name:       "ItemLoadingView",
    components: { Item },
    data() {
        return {
            contextualCommentText: '',
            deletingComment:       '',
            item:                  this.$route.params.item,
            loading:               null,
            ride:                  this.$route.params.ride,
            settings:              getSettings(),
            storingComment:        false,
        }
    },
    created() {
        if (this.contextualComment) this.contextualCommentText = this.contextualComment.comment;
    },
    computed: {
        contextualComment() {
            if (! this.purchaseReceipt) return null;

            let comments = this.purchaseReceipt.contextualComments.filter((comment) => comment.context === CONTEXT.PURCHASE_RECEIPT_EXCEPTION);

            return comments.length > 0
                ? comments[0]
                : null
            ;
        },
        commentIsEditable() {
            return this.ride.status.code > RIDE_STATUS.CONCEPT && this.item.itemStatus < ITEM_STATUS.COMPLETED;
        },
        disableCommentActions() {
            return this.loading || this.deletingComment || this.storingComment || this.item.itemStatus === ITEM_STATUS.COMPLETED;
        },
        disableDeleteComment() {
            return ! this.contextualComment || this.disableCommentActions;
        },
        disableStoringComment() {
            return this.contextualCommentText === '' || this.disableCommentActions;
        },
        purchaseReceipt() {
            return this.item.purchaseReceipt;
        },
    },
    methods: {
        goBack()
        {
            this.$router.push({
                name: 'item_options_view',
                params: {
                    item:   this.item,
                    itemId: this.item.id,
                    ride:   this.ride
                }
            });
        },
        async storeContextualComment() {
            try {
                this.storingComment = true;
                const response = await apiClient.post(
                    `transport/purchase_receipts/${this.purchaseReceipt.id}/contextualComments`,
                    { comment: this.contextualCommentText.trim(), context: CONTEXT.PURCHASE_RECEIPT_EXCEPTION }
                );
                this.$set(this.item, 'purchaseReceipt', response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.storingComment        = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        async updateContextualComment() {
            try {
                this.storingComment = true;
                const response = await apiClient.put(
                    `transport/purchase_receipts/${this.purchaseReceipt.id}/contextualComments/${this.contextualComment.id}`,
                    { comment: this.contextualCommentText.trim() }
                );
                this.$set(this.item, 'purchaseReceipt', response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.storingComment        = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        async deleteContextualComment() {
            try {
                this.deletingComment = true;
                const response       = await apiClient.delete(`transport/purchase_receipts/${this.purchaseReceipt.id}/contextualComments/${this.contextualComment.id}`);
                this.$set(this.item, 'purchaseReceipt', response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.deletingComment       = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        scrollTop() {
            // Cannot be a computed property because it won't update (because DOM elements are not reactive)
            let overlay = document.querySelector(".overlay .uk-modal-dialog");
            if (overlay) {
                return `${overlay.scrollTop}px`;
            }
            return 0;
        },
        async unloadAll(item) {
            try {
                this.loading = 'all';

                const response = await apiClient.put(`transport/purchase_receipts/${item.purchaseReceipt.id}/load`);

                this.$set(this.item, 'purchaseReceipt', response.data.data);

            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.loading = null;
            }
        },
        async updateLineLoadStatus({ line, item }) {
            try {
                this.loading = line.id;

                const response = await apiClient.put(
                    `transport/purchase_receipts/${item.purchaseReceipt.id}/lines/${line.id}`,
                    { loadStatus: line.loadStatus }
                );
                const lines = this.item.purchaseReceipt.lines;
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i].id === line.id) {
                        lines.splice(i, 1, response.data.data);
                        break;
                    }
                }
                await this.updateStatuses();
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.loading = null;
            }
        },
        async updateStatuses() {
            if (this.ride.status.code < RIDE_STATUS.IN_TRANSIT) {
                try {
                    const response = await apiClient.put(
                        `transport/transport_rides/${this.ride.id}?include=${SINGLE_RIDE_INCLUDES}`,
                        { status: RIDE_STATUS.IN_TRANSIT }
                    );
                    this.$set(this, 'ride', response.data.data);
                } catch (e) {
                    console.error(e); //eslint-disable-line no-console
                    throw e;
                }
            }
            let allLinesLoaded = true;
            for (const line of this.item.purchaseReceipt.lines) {
                if (line.loadStatus < PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED) {
                    allLinesLoaded = false;
                    break;
                }
            }
            if (allLinesLoaded && this.item.itemStatus < ITEM_STATUS.LOADED) {
                const response = await apiClient.put(
                    `transport/items/${this.item.id}`,
                    { status: ITEM_STATUS.LOADED }
                );
                this.$set(this, 'item', response.data.data);

            } else if (! allLinesLoaded && this.item.itemStatus === ITEM_STATUS.LOADED) {
                const response = await apiClient.put(
                    `transport/items/${this.item.id}`,
                    { status: ITEM_STATUS.UNDERWAY }
                );
                this.$set(this, 'item', response.data.data);
            }
        }
    },
};
</script>
