import { getSettings } from "@/support/settings";
import roundTo         from "@/support/roundTo";

/**
 * @param numberToFormat
 * @param returnAsNumber {Boolean}
 * @return {String|Number}
 */
function formatQuantity(numberToFormat, returnAsNumber = false) {
    return roundTo(numberToFormat, getSettings().quantity_max_decimals, returnAsNumber);
}
export default formatQuantity;
